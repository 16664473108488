import React, { useState, useEffect } from 'react';

import {
    Container,
    Paper,
    Grid,
    Button,
    Box,
    Typography
  } from '@mui/material';

  import VIASC from'./Img/3cfd38_14fef54b13614fc587c97afb15b07c21.webp'
  import AAA from'./Img/aaa.png'



function Asociaciones() {

    
    const [zoomIndex, setZoomIndex] = useState(0);
    const zoomLevels = [1.2, 0.8]; // Niveles de zoom predefinidos
  
    useEffect(() => {
        const intervalId = setInterval(() => {
          setZoomIndex(prevIndex => (prevIndex + 1) % zoomLevels.length); // Cambia al siguiente nivel de zoom
        }, 1000); // Cambia el zoom cada 2 segundos
    
        return () => clearInterval(intervalId); // Limpia el intervalo cuando el componente se desmonta
      }, []);
    return(
<div style={{backgroundColor:'white',padding:9  }}>
      <Container id="Asociaciones">
      <Typography sx={{fontSize:'30px'}}>Nuestras <b>Alianzas</b></Typography> 

<Grid container>
<Grid item xs={12} md={6}><img src={VIASC} style={{width:200, transform: `scale(${zoomLevels[zoomIndex]})`, transition: 'transform 1s ease-in-out' }} ></img></Grid>
<Grid item xs={12} md={6}><img src={AAA} style={{width:300, transform: `scale(${zoomLevels[zoomIndex]})` , transition: 'transform 1s ease-in-out'}} ></img></Grid>
</Grid>


      </Container>
      </div>);


}

export default Asociaciones;

