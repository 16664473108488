import {
    Container,
    Typography,
    Paper,
    Grid,
    Button,
    Box
  } from '@mui/material';
  import React, { useState } from 'react';

  import theme1 from './themes/tema1';

  import { ThemeProvider } from '@mui/material/styles';
  import ej1 from './Img/ej1.jpg';
  import ej2 from './Img/ej2.jpg';
  import ej3 from './Img/ej3.jpg';
  import ej4 from './Img/ej4.jpg';
  import ej5 from './Img/ej5.jpg';
  import ej6 from './Img/ej6.jpg';
  import ej7 from './Img/ej7.jpg';
  import ej8 from './Img/ej8.jpg';
  import ej9 from './Img/ej9.jpg';
  import ej10 from './Img/ej10.jpg';
  import Mision from './Img/Metas.png';
  import MobileStepper from '@mui/material/MobileStepper';
  import List from '@mui/material/List';
  import ListItem from '@mui/material/ListItem';
  import ListItemText from '@mui/material/ListItemText';

  import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
  import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
  import SwipeableViews from 'react-swipeable-views';
  import { autoPlay } from 'react-swipeable-views-utils';
  
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

  

 

  
  function SeccionAcercade() {


    const images = [
      {imgPath:ej1,label:'1'},
      {imgPath:ej2,label:'2'},
      {imgPath:ej3,label:'3'},
      {imgPath:ej4,label:'4'},
      {imgPath:ej5,label:'5'},
      {imgPath:ej6,label:'6'},
      {imgPath:ej7,label:'7'},
      {imgPath:ej8,label:'8'},
      {imgPath:ej9,label:'9'},

      // ... Agrega más URLs de imágenes según sea necesario
    ];
  
  const theme =theme1;


  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

 
    return (
      <ThemeProvider theme={theme1}>
      <Box sx={{  flexGrow: 1} } >
 <a></a>
 <AutoPlaySwipeableViews
   index={activeStep}
   onChangeIndex={handleStepChange}
   enableMouseEvents
 >
   {images.map((step, index) => (
     <div key={step.label}  >
       {Math.abs(activeStep - index) <= 2 ? (
         <Box
           component="img"
           sx={{
             height: 255,
             display: 'block',
             overflow: 'hidden',
             width: '100%',
             objectFit:'cover',
             minHeight:400,
             maxHeight:400,
           }}
           
           src={step.imgPath}
           alt={step.label}
           color={'secondary'}

         />
       ) : null}
     </div>
   ))}
 </AutoPlaySwipeableViews>
 <MobileStepper
   steps={maxSteps}
   position="static"
   activeStep={activeStep}
   nextButton={
     <Button
       size="small"
       onClick={handleNext}
       disabled={activeStep === maxSteps - 1}
     >
       Siguiente
       {theme.direction === 'rtl' ? (
         <KeyboardArrowLeft />
       ) : (
         <KeyboardArrowRight />
       )}
     </Button>
   }
   backButton={
     <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
       {theme.direction === 'rtl' ? (
         <KeyboardArrowRight />
       ) : (
         <KeyboardArrowLeft />
       )}
       Regresar
     </Button>
   }
 />
</Box>
        <Container id='SeccionAcercade' component="main" maxWidth="lg" sx={{ mt: 4 }}>


          <Grid container spacing={3}>
            <Grid item xs={12} lg={6} sx={{
              mb: 5
            }} >
              <Paper elevation={3} sx={{ p: 3 }}>
                <Typography variant="h3" component="div" gutterBottom>
                  CVAL
                </Typography>
                <Typography variant="h5" component="div" gutterBottom>
                  AVALUOS Y CONSTRUCCION
                </Typography>
                <Typography variant="body1" paragraph >
                  SOMOS UNA EMPRESA FUNDADA EN EL 2006, ENFOCADOS AL DESARROLLO DE LA CONSTRUCCIÓN Y VALUACIÓN DE INMUEBLES, LIDERADO POR UN EQUIPO PROFESIONAL EFICIENTE DE ARQUITECTOS Y VALUADORES CAPACITADOS.
                </Typography>
              </Paper>
            </Grid>
            
            {/* Agrega más secciones según sea necesario */}
            <Grid item xs={12} lg={6} >
              <Paper elevation={3} sx={{m:1, p: 3}}>
                <Typography variant="h5" component="div" gutterBottom>
                  Misión
                </Typography>
                <Typography variant="body1" paragraph>
                BRINDAR SERVICIOS PROFESIONALES CUMPLIENDO CON TODOS LOS LINEAMIENTOS NORMATIVOS, TÉCNICOS Y DE CALIDAD, CONTANDO CON EL PERSONAL PROFESIONAL DISEÑADAS A LAS NECESIDADES DEL CLIENTE.
                </Typography>
                <img src={Mision} alt='Logo mision' width={50}></img>
              </Paper>
              </Grid>
              </Grid>
              <Grid xs={12} >

              <Paper elevation={3} sx={{ m:1, p: 3, height: '40%' }}>
                <Typography variant="h5" component="div" gutterBottom>
                  Valores
                </Typography>
                <Grid container >
                <Grid item xs={12} lg={6}>

                <List >
                <ListItem  >
          <ListItemText  sx={{m:0}}  primary="PERSONAL CAPACITADO Y PROFESIONAL." />

        </ListItem>
        <ListItem  >
          <ListItemText sx={{m:0}} primary="COMPROMISO Y RESPONSABILIDAD." />

        </ListItem>
        <ListItem  >
        <ListItemText sx={{m:0}} primary="INTEGRIDAD Y HONESTIDAD." />
        </ListItem>

        <ListItem  >
        <ListItemText sx={{m:0}} primary="INNOVACIÓN Y CREATIVIDAD." />
        </ListItem>

        <ListItem>
          <ListItemText sx={{m:0}} primary="ACTITUD DE SERVICIO." />
        </ListItem>
        </List>

                </Grid>
                <Grid item xs={12} lg={6}>

                <List>
        <ListItem>
          <ListItemText  sx={{m:0}} primary="ORIENTACIÓN AL CLIENTE." />
        </ListItem>
        <ListItem>
          <ListItemText sx={{m:0}} primary="EXCELENCIA." />
        </ListItem>
        <ListItem>
          <ListItemText sx={{m:0}} primary="ACTITUD POSITIVA, ALEGRÍA Y PASIÓN." />
        </ListItem>
        <ListItem>
          <ListItemText sx={{m:0}} primary="RESPETO Y HUMILDAD." />
        </ListItem>
        <ListItem>
          <ListItemText sx={{m:0}} primary="TRABAJAR CON SEGURIDAD." />
        </ListItem>
                ,
            </List>
                </Grid>
                </Grid>
                <Typography variant="body1" paragraph >
               
                        
            </Typography>
              </Paper>
              </Grid>

          

        </Container>
  
     </ThemeProvider>
    );
  }
  
  export default SeccionAcercade;
  