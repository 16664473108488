import React, { useState, useEffect } from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import {
    Container,
    Paper,
    Grid,
    Button,
    Box,
    Typography,
    Icon
  } from '@mui/material';


function Footer() {

    return(
<div style={{backgroundColor:'#282c34',padding:9,color:'white' }}>
      <Container id="Footer">

<Grid container>
<Grid item xs={12} md={4} sx={{p:2}}>

<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15052.349704883616!2d-102.0513216!3d19.4086282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842de3aa2ff6455f%3A0xb137806c4c1e432a!2sCval%20Grupo%20Inmobiliario%20del%20Cupatitzio%2C%20S.A.%20de%20C.V.!5e0!3m2!1ses-419!2smx!4v1710800546908!5m2!1ses-419!2smx"
 width="100%" height="auto" style={{border:0}}     loading="lazy" ></iframe>
<Typography>
PROLONGACIÓN GRAN PARADA, 1125 ALTOS COLONIA FERROCARRILERA
URUAPAN, MICHOACÁN MÉXICO C.P. 60150
<br/><b>TEL. OFICINA:</b> 452 148 4043
</Typography>

    </Grid>

    <Grid item xs={12} md={4} sx={{p:2}}>

    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.8878910470717!2d-102.05397972501358!3d19.417249281858837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842de26853e6c9bd%3A0xefc31e4af5f56a9d!2sP.%C2%BA%20L%C3%A1zaro%20C%C3%A1rdenas%2010-INT.%20103%2C%20Centro%2C%2060080%20Uruapan%2C%20Mich.!5e0!3m2!1ses-419!2smx!4v1710800936808!5m2!1ses-419!2smx" 
    width="100%" height="auto" style={{border:0}} loading="lazy" ></iframe><Typography>
PASEO LAZARO CARDENAS NO. 10 INT. 103 COL. MORELOS 
URUAPAN, MICHOACÁN MÉXICO C.P. 60150
<br/><b>TEL. OFICINA:</b> 452 292 5760
</Typography>

    </Grid>

    <Grid item xs={12} md={4} sx={{p:2}}>

<a style={{color:'white'}} href='https://www.facebook.com/cvaluacionconstruccion' target="_blank"><FacebookIcon sx={{fontSize:50}}></FacebookIcon> 
<Typography >
Facebook
</Typography>
</a>
</Grid>
</Grid>



      </Container>
      </div>);


}

export default Footer;

