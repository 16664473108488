import React from 'react';
import logo from './Img/LOGOFONDO.png';
import ResponsiveAppBar from './ResponsiveAppBar';
import SeccionAcercade from './SeccionAcercade';
import Seccionservicios from './Servicios';
import Asociaciones from './Asociaciones';
import Footer from './Footer';

import './App.css';

function App() {
  return (
    <div className="App" > 
           <title>CVAL</title>
            <div
        className="background-container"
        style={{
          backgroundImage: `url(${logo})`, // Ruta de la imagen de fondo
          backgroundPosition: 'center center', // Centra la imagen de fondo
          position: 'relative',
        }}
      >
         <ResponsiveAppBar />
            <SeccionAcercade /> 
            <Seccionservicios/>
        <Asociaciones/>
        <Footer/>
      </div>
 
    </div>
  );
}

export default App;
