
import {
    Container,
    Paper,
    Grid,
    Button,
    Box
  } from '@mui/material';

  import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
  import React, { useState } from 'react';

function Seccionservicios(){
return(
    <div style={{backgroundColor:'rgba(52, 52, 52, .9)',padding:10}}>
      <Container  id="Servicios">
      <Typography sx={{fontSize:'30px',color:'white',}}><b>SERVICIOS DE CONTRUCCION</b></Typography> 

        <Grid container sx={{p:4}}>
        <Grid item xs={12} md={6} sx={{p:1}} >
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>ANTEPROYECTO DE OBRA</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography >
        PERMITE VISUALIZAR DE MANERA GENERAL TODOS LOS CONCEPTOS ARQUITECTÓNICOS Y DISEÑOS DE OBRA; SE CONFORMAN DE LA SIGUIENTE MANERA:
        </Typography>
        <Typography  sx={{textAlign:'left'}}>

       <ul style={{listStyle:'none'}}> <li>1.	CONCEPTOS ARQUITECTONICOS.</li>
          <li>2.	DISEÑOS 3D Y RECORRIDOS VIRTUALES.</li>  
          <li>3.	ELABORACIÓN DE PLANOS.
</li>
          <li>4.	DETALLES CONSTRUCTIVOS.
</li>
</ul>


        </Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>PROYECTO EJECUTIVO </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography >
        UN PROYECTO EJECUTIVO LE PERMITE DEFINIR TODOS LOS PROCESOS Y ETAPAS INVOLUCRADOS EN LA CONSTRUCCIÓN DE UNA OBRA Y DE ESTA MANERA OPTIMIZAR SUS RECURSOS.        </Typography>
        <Typography  sx={{textAlign:'left'}}>

       <ul style={{listStyle:'none'}}>
<li>1.	CALCULO ESTRUCTURAL.</li>
<li>2.	INSTALACIONES SANITARIAS.</li>
<li>3.	INSTALACIONES HIDRAULICAS.</li>
<li>4.	INSTALACIONES ELECTRICAS.</li>
<li>5.	INSTALACIONES DE GAS.</li>
<li>6.	INTALACIONES ESPECIALES.</li>

</ul>


        </Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>PRESUPUESTOS DE OBRA</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography >
        NOS PERMITE DAR UN COSTO ESTIMADO DEL PROYECTO DANDO UNA VALORIZACION MAS CERCANA A LA OBRA. SE CONFORMAN DE LA SIGUIENTE MANERA:        </Typography>
        <Typography  sx={{textAlign:'left'}}>

       <ul style={{listStyle:'none'}}> 
     <li>1.	CONCEPTOS DE OBRA.</li>
     <li>2.	ANÁLISIS DE PRECIOS UNITARIOS.</li>
     <li>3.	EXPLOSIÓN DE INSUMOS.</li>

</ul>


        </Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>CONTROL, ADMINISTRACIÓN Y EJECUCION DE OBRA</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography >
        TIENE POR OBJETIVO, ORGANIZAR, PLANIFICAR  TODOS LOS RECURSOS PARA TERMINAR EN TIEMPO Y FORMA TODOS LOS PROCESOS DE LA OBRA: 
                       </Typography>
        <Typography  sx={{textAlign:'left'}}>

       <ul style={{listStyle:'none'}}> 
    <li>1.	PLAN DE COMPRAS.</li>
    <li>2.	PLAN DE SUBCONTRATOS.</li>
    <li>3.	PLAN DE MAQUINARIA Y EQUIPO.</li>
    <li>4.	PLAN DE MANO DE OBRA.</li>
    <li>5.	PLAN DE ESTIMACIONES.</li>
    <li>6.	CONTROL DE AVANCE.</li>
    <li>7.	REPORTES FOTOGRÁFICOS.</li>


</ul>


        </Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>CONSTRUCCIÓN RESIDENCIAL Y COMERCIAL</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography >
        TRABAJAMOS DE FORMA PERSONALIZADA CON CLIENTES DE OBRA RESIDENCIAL Y COMERCIAL PARA SATISFACER SUS NECESIDADES; CUMPLIENDO CON LOS REQUERIMIENTOS Y ESPECIFICACIONES ESPECIALES.            </Typography>
    
      </AccordionDetails>
    </Accordion>
    </Grid>
    <Grid item xs={12} md={6} sx={{p:1}} >

       <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>REMODELACIONES Y MANTENIMIENTO.</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography >
        PROPORCIONAMOS SOLUCIONES INTEGRALES TANTO COMO RESIDENCIAL Y COMERCIAL:<br/>CONTANDO CON PERSONAL CAPACITADO; ELECTRICISTAS, PLOMEROS, PINTORES, PERSONAL DE OBRA, CON LA FINALIDAD DE BRINDARTE UN SERVICIO COMPLETO A ENTERA SATISFACCIÓN DEL CLIENTE.
                </Typography>
        
      </AccordionDetails>
    </Accordion> 
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>LEVANTAMIENTO TOPOGRÁFICO</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography >
        ANALIZAMOS CUALQUIER SUPERFICIE DE TERRENO INDICANDO LAS MEDIDAS GEOPOSICIONADAS CON CORDENAS, ALTURAS SOBRE EL NIVEL DEL MAR, ASI TAMBIÉN COMO DELIMITACIÓN DE PARCELAS Y HUERTAS.
                        </Typography>
        
      </AccordionDetails>
    </Accordion>   
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>PROYECTOS DE SUBDIVISIÓN</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography >
        SUBDIVIDIMOS Y FUCIONAMOS PORCIONES DE TERRENOS, UBICACIÓN DE AREAS COMUNES CON EQUIPO DE POSICIONAMIENTO GEOGRAFICO.
                                </Typography>
        
      </AccordionDetails>
    </Accordion> 
    
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>DISEÑO DE MUEBLES DE COCINA, CLOSET Y CARPINTERÍA GENERAL</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography >
        FABRICAMOS CUALQUIER TIPO DE MUEBLES CON MANO DE OBRA CALIFICADA.
                               </Typography>
        <Typography  sx={{textAlign:'left'}}>

       <ul style={{listStyle:'none'}}> 
    <li>1.	MUEBLES A MEDIDA.</li>
    <li>2.	DIFERENTES DISEÑOS DE CLOSET Y COCINAS CON MATERIALES DE ALTA CALIDAD.</li>
</ul>


        </Typography>
      </AccordionDetails>
    </Accordion>
    </Grid>  </Grid>

    <Typography sx={{fontSize:'30px',color:'white'}}><b>SERVICIOS DE VALUACION</b></Typography> 

        <Grid container sx={{p:4}}>
        <Grid item xs={12} md={6} sx={{p:1}} >
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>AVALUOS PARA CREDITOS HIPOTECARIOS INFONAVIT, FOVISTE, BANCARIOS</Typography>
      </AccordionSummary>
      <AccordionDetails>

      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>AVALUOS PARA CREDITERRENO INFONAVIT</Typography>
      </AccordionSummary>
      <AccordionDetails>

      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>AVALUOS FISCALES Y CATASTRALES</Typography>
      </AccordionSummary>
      <AccordionDetails>

      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>AVALUOS COMERCIALES</Typography>
      </AccordionSummary>
      <AccordionDetails>

      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>AVALUOS AGROPECUARIOS</Typography>
      </AccordionSummary>
      <AccordionDetails>
      
      </AccordionDetails>
    </Accordion>
    
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>OPINIONES DE VALOR</Typography>
      </AccordionSummary>
      <AccordionDetails>
        
      </AccordionDetails>
    </Accordion> 
    
    
    </Grid>
    <Grid item xs={12} md={6} sx={{p:1}} >

       <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>AVALUOS PARA CREDITERRENO INFONAVIT</Typography>
      </AccordionSummary>
      <AccordionDetails>
        
      </AccordionDetails>
    </Accordion> 
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>AVALUOS INMOBILIARIA INDUSTRIAL</Typography>
      </AccordionSummary>
      <AccordionDetails>
        
      </AccordionDetails>
    </Accordion>   
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>VALUACION DE IMPANTO AMBIENTAL</Typography>
      </AccordionSummary>
      <AccordionDetails>

      </AccordionDetails>
    </Accordion> 
    
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>VALUACION DE MAQUINARIA Y EQUIPO</Typography>
      </AccordionSummary>
      <AccordionDetails>

      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>VALUACION DE NEGOCIOS EN MARCHA</Typography>
      </AccordionSummary>
      <AccordionDetails>

      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header">
        <Typography >VALUACION DE ACTIVOS PARA REEXPRESION DE ESTADOS FINANCIEROS</Typography>
      </AccordionSummary>
      <AccordionDetails>

      </AccordionDetails>
    </Accordion>
    </Grid>  </Grid>
</Container>
  </div>

);
}

export default Seccionservicios;